import styled from "styled-components";

import { Box } from "../Grid";
import themeGet from "@styled-system/theme-get";

const BreadcrumbItem = styled(Box)`
  position: relative;
  padding-right: 8px;
  margin-right: 8px;
  font-size: ${themeGet("fontSizes.1")};
  line-height: ${themeGet("fontSizes.1")};
  &:after {
    position: absolute;
    top: 50%;
    right: -4px;
    content: "/";
    transform: translateY(-50%);
  }
  &:last-of-type {
    padding-right: 0;
    margin-right: 0;
    color: ${themeGet("colors.lastBreadcrumb")};
    a {
      text-decoration: none;
      pointer-events: none;
    }
    &:after {
      display: none;
    }
  }
  a {
    text-decoration: underline;
    color: ${themeGet("colors.linkSecondary")};
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
`;

BreadcrumbItem.defaultProps = {};

export { BreadcrumbItem };
