import React from "react";
import Link from "../Link";
import { BreadcrumbItem, BreadcrumbWrapper } from "../../style/Breadcrumb";
import { URLS } from "../../const/const";

const Breadcrumb = ({ breadcrumbs, mb, mt }) => (
  <BreadcrumbWrapper mb={mb ? mb : null} mt={mt ? mt : null}>
    <BreadcrumbItem>
      <Link href={URLS.home}>KAPITOL</Link>
    </BreadcrumbItem>
    {breadcrumbs.map((crumb, index) =>
      breadcrumbs.length - index > 1 ? (
        <BreadcrumbItem key={index}>
          <Link href={crumb.link}>{crumb.label}</Link>
        </BreadcrumbItem>
      ) : (
        <BreadcrumbItem key={index}>{crumb.label}</BreadcrumbItem>
      )
    )}
  </BreadcrumbWrapper>
);

export default Breadcrumb;
