import styled from "styled-components";
const ArticleStyle = styled.article`
  width: 100%;
  max-width: 794px;
  margin: 0 auto 32px;
  font-size: 16px;

  &:not(:last-child) {
    margin-bottom: 64px;
  }

  a,
  a:link,
  a:visited {
    color: ${themeGet("colors.link")};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: 600;
  }

  h2 {
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    color: ${themeGet("colors.headlines")};
    text-transform: uppercase;
    margin-top: 48px;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 28px;
    line-height: 29px;
    font-weight: 300;
    color: ${themeGet("colors.headlines")};
  }

  h4 {
    font-size: 20px;
    line-height: 23px;
    color: ${themeGet("colors.headlinesSecondary")};
    margin-top: 12px;
    margin-bottom: 6px;
  }

  & > p,
  & > ul,
  & > ol {
    color: ${themeGet("colors.bodyText")};
  }

  & > p,
  & > ul,
  & > ol {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  & > p:first-child,
  & > h2:first-of-type + p:first-of-type {
    color: ${themeGet("colors.headlines")};
    font-size: 20px;
    line-height: 23px;
  }

  & > p:first-child {
    margin-top: ${props => (props.simplePage ? "24px" : "48px")};
    margin-bottom: ${props => (props.simplePage ? "24px" : "44px")};
  }

  p:empty {
    min-height: 24px;
  }

  .wp-block-image {
    flex-direction: column;
    .alignright {
      float: right;
      @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
        float: none;
      }
    }
    .alignleft {
      float: left;
      @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
        float: none;
      }
    }
    @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
      display: flex;
      justify-content: center;
    }

    figure {
      margin: 0;
      padding: 0 20px;
      img {
        max-width: 250px;
      }
    }
  }

  .wp-block-table {
    margin: 0;
    margin-bottom: 24px;
    padding: 0;
    table {
      width: 100%;
      th {
        text-align: left;
      }
    }
  }

  ol {
    margin-left: 20px;
    & > li {
	    font-size: 16px;
	    line-height: 19px;
	    margin-top: 16px;
	    margin-bottom: 16px;
	    & > ol {
		    padding-left: 20px;
	    }
    }
  }

  ul {
    padding-left: 20px;
    & > li {
      font-size: 16px;
      line-height: 19px;
      margin-top: 16px;
      margin-bottom: 16px;
      list-style-type: disc;
      & > ul {
        padding-left: 20px;
        & > li {
            list-style-type: circle;
        } 
      }
    }
  }

  @media screen and (max-width: ${themeGet("breakpoints.md")}) {
    & > p,
    & > ul,
    & > ol {
      a {
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }
  }

  blockquote {
    position: relative;

    max-width: 590px;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 35px 30px 35px 15px;
    font-size: 20px;
    line-height: 23px;
    color: ${themeGet("colors.headlines")};

    border-left: 4px solid ${themeGet("colors.cta")};
    border-width: 0 0 0 4px;
    border-style: solid;
    border-image: linear-gradient(90deg, #00b8a6 0%, #a4d9ce 100%) 1 100%;

    & > p:only-child {
      margin: 0;
    }

    &:before {
      content: "“";
      position: absolute;
      top: 24px;
      left: 10px;
      font-size: 100px;
      font-weight: 300;
      color: ${themeGet("colors.headlines")};
    }
    &:after {
      content: "„";
      position: absolute;
      bottom: 36px;
      right: 0;
      font-size: 100px;
      font-weight: 300;
      color: ${themeGet("colors.headlines")};
    }

    //TODO: prepsat
    margin-left: ${(794 - 1200) / 2}px;

    @media screen and (max-width: 1260px) {
      margin-left: calc(((62px + 794px) - 100vw) / 2);
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  ${props =>
  props.links && {
    "& > h2 + ul, & > h3 + ul, & > h4 + ul": {
      listStyleType: "none",
      marginTop: 16,
      "& > li": {
        counterIncrement: "none",
        position: "relative",
        paddingLeft: 30,
        textIndent: 0,
        color: themeGet("colors.headlinesSecondary")(props),
        "&:before": {
          position: "absolute",
          left: 0,
          top: 6,
          width: 18,
          height: 11,
          backgroundImage: `url("../../assets/images/icons/link-icon.svg")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          content: "''",
          color: themeGet("colors.link")(props),
          marginRight: 0,
        },
      },
    },
  }}

  ${props =>
  props.attachments && {
    "& > h2 + ul, & > h3 + ul, & > h4 + ul": {
      listStyleType: "none",
      marginTop: 16,
      "& > li": {
        counterIncrement: "none",
        position: "relative",
        paddingLeft: 30,
        textIndent: 0,
        color: themeGet("colors.headlinesSecondary")(props),
        "&:before": {
          position: "absolute",
          left: 0,
          top: 3,
          height: 21,
          backgroundImage: `url("../../assets/images/icons/document-icon.svg")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          content: "'PDF'",
          fontSize: 9,
          lineHeight: "11px",
          fontWeight: 900,
          color: themeGet("colors.link")(props),
          display: "flex",
          alignItems: "flex-end",
          marginRight: 0,
        },
      },
    },
  }}
`;

import themeGet from "@styled-system/theme-get";

ArticleStyle.displayName = "Article";

ArticleStyle.defaultProps = {};

ArticleStyle.propTypes = {};

export default ArticleStyle;
