import styled from "styled-components";

import { Box } from "../Grid";

const BreadcrumbWrapper = styled(Box)`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

BreadcrumbWrapper.defaultProps = {
  p: 0,
};

export { BreadcrumbWrapper };
