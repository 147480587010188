import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &:last-of-type {
    margin-bottom: 64px;
  }

  ul {
    list-style-type: none;
  }

  p,
  figure {
    img {
      width: 100%;
      height: auto;
    }
  }

  & > div {
    max-width: ${(1200 - 794) / 2 + 794}px;

    & > ul {
      padding-bottom: 10px;
      border-bottom: 1px solid ${themeGet("colors.forms.border")};

      li:not(:last-of-type) {
        margin-right: 6px;
        @media screen and (max-width: ${themeGet("breakpoints.md")}) {
          margin-right: 8px;
        }
      }

      li:first-of-type {
        margin-right: 20px;
        @media screen and (max-width: ${themeGet("breakpoints.md")}) {
          margin-right: auto;
        }
      }
    }
  }

  aside {
    a {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: ${themeGet("colors.linkSecondary")};
      transition: all 200ms;
      svg,
      svg > path {
        transition: all 200ms;
      }
      &:hover,
      &:active {
        color: ${themeGet("colors.headlines")};
        svg,
        svg > path {
          stroke: ${themeGet("colors.headlines")};
        }
      }
      &:first-of-type svg {
        margin-right: 6px;
      }
      &:last-of-type svg {
        margin-left: 6px;
      }
    }
  }
`;

ArticleContainer.displayName = "Article";

ArticleContainer.defaultProps = {
  width: ["100%", null, null, null, "container"],
  maxWidth: ["100%", null, null, "794px", null],
  mx: [null, null, null, null, "-15px"],
  px: ["15px", null, "31px", null, "15px"],
};

ArticleContainer.propTypes = {};

export default ArticleContainer;
