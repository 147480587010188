import React, { useEffect } from "react";
import { graphql } from "gatsby";
import slugify from "slugify";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";

import ArticleContainer from "../../style/Article/ArticleContainer";
import { Heading } from "../../style/Heading";
import { Container, Flex, Wrapper } from "../../style/Grid";
import ArticleStyle from "../../style/Article";

import { htmlDecode } from "../../utils";

const PageWithSectionsTemplate = ({
  location,
  uri,
  data: {
    wp: {
      pageBy: {
        title,
        pageContent: { sections },
        seo: { metaDesc },
      },
    },
  },
}) => {
  const breadcrumbs = [
    {
      label: htmlDecode(title),
      link: uri,
    },
  ];

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.slice(1);
      const targetElement = document.getElementById(hash);

      if (document.body.contains(targetElement)) {
        const headerElement = document.querySelector("header");
        scrollTo({
          top: targetElement.offsetTop - (headerElement.offsetHeight + 10),
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column">
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              {htmlDecode(title)}
            </Heading>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container flexDirection="column">
          {sections &&
            sections.map(({ sectionname: name, sectioncontent: content }) => {
              const id = slugify(name).toLowerCase();
              return (
                <ArticleContainer key={id} id={id}>
                  <ArticleStyle dangerouslySetInnerHTML={{ __html: content }} />
                </ArticleContainer>
              );
            })}
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default PageWithSectionsTemplate;

export const pageQuery = graphql`
  query PAGE_WITH_SECTIONS_QUERY($uri: String) {
    wp {
      pageBy(uri: $uri) {
        title
        pageContent {
          template
          sections {
            sectioncontent
            sectionname
          }
        }
        seo {
          metaDesc
        }
      }
    }
  }
`;
